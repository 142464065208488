import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({location}) => (
  <Layout>
    <SEO title="404: Niet gevonden" />
    <h1>Niet gevonden</h1>
    <p>Helaas, deze pagina bestaat niet. Ga terug naar de <Link to="/">voorpagina</Link>.</p>
  </Layout>
)

export default NotFoundPage
